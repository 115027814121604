import { useNavigate } from 'react-router-dom';
import './index.scss'
import React from 'react';
import { Row, Layout, Image, Col } from 'antd';
const { Header, Content, Footer } = Layout;
class Profile extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      title:'科技动态',
    }
  }

  componentDidMount() {
  }

  componentWillReceiveProps(nextProps) {
  }
  render() {
    const { code } = this.state;

    return (
      <Layout style={{ width: '1200px', margin: '0 auto' }}>
        <Row justify='center'>
          <Col className='module-content' span={6}>
            <div className="fadeUp">
              <div className="title-en">TRENDS</div>
              <div className="title-cn">科技动态</div>
            </div>
          </Col>
          <Col className='news-detail' span={18}>
            <Row justify='center' align='right' style={{ justifyContent: 'right' }}>
              <Col span={22} style={{ minHeight: '500px'}} >
               
              </Col>
            </Row>
          </Col>
        </Row>
      </Layout>
    )
  }
}


export default Profile;
