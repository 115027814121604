const datas = {
    new_datas: [
        {
            addr: '/news/one',
            date: '2024.02.27',
            title: '温州海经区领导调研瓯江口机器人产业园',
            img: require('../../assets/images/news2402298.jpg'),
            author: 'frankmiao',
            content: {
                subheading: `2月26日，温州海经区党工委书记、管委会主任徐建兵一行在瓯江口机器人产业园进行了走访调研。`,
                data: [
                    { img: require('../../assets/images/news2402298.jpg'), text: `徐建兵书记十分关心机器人产业的发展。在园区内，他仔细询问了企业入驻情况，了解企业生产经营中遇到的难题，还深入已入驻的浙江斯普威尔电气有限公司，察看企业车间生产情况。针对企业提出的人才引进难、园区配套未完善等问题，并叮嘱有关部门要主动对接、靠前服务，精心呵护机器人这一被誉为“制造业皇冠顶端的明珠”的产业；同时，要加快科技创新，提升高端制造业水平，为海经区经济社会发展注入强力动能。
徐书记强调，要深入学习贯彻习近平总书记考察浙江重要讲话精神，认真落实全省、温州市和海经区“新春第一会”精神，坚持以营商环境优化来激发活力、以作风建设破题开局，实现政企同心、双向奔赴，以精准化服务助力企业高质量发展，奋力开创平台建设新局面。`},
                    { img: require('../../assets/images/news2402299.jpg'), text: 
                    `海经区管委会领导周海波、陈楠、吴晨宇等参加了走访调研。` },
                    { img: require('../../assets/images/news24022910.png'), text: '温州瓯江口机器人产业园项目总建筑面积62万平方米，被列为浙江省“152”重点产业项目，总投资25亿元。园区建有机器人研发和生产基地、机器人应用示范区、机器人博物馆及配套工程，形成了一个由机器人制造、系统集成、零部件生产、智能产业示范等生态链组成的产业集群。目前，温州瓯江口机器人产业园已投产41家企业，另有33家正在装修、准备入驻。' },
                ],
                summarize: '机器人产业将为温州转型升级和智能制造的发展提供有力支撑。过去两年，瓯江口机器人产业园联合达明机器人和温州技师学院，共建工业机器人产业学院，打造了“校、园、企”三位一体的职业教育联合体，为温州机器人应用产业培养高素质技能人才赋能助力。'
            }
        },
        {
            addr: '/news/one',
            date: '2023.12.23',
            title: '富轹科技冬季团建 学习 ·沟通 ·聚力',
            img: '',
            author: 'frankmiao',
            content: {
                subheading: `2023年12月23日，浙江富轹科技集团在温州九珑湖举办了年度末第二次团队建设活动。`,
                data: [
                    { img: require('../../assets/images/news2402291.png'), text: '本次团建活动继续围绕“全员营销”既定战略的课题，强化对企业文化的深刻理解。为了激发员工对公司战略的深刻认识和热情，邀请了各业务板块专家授课，通过对公司各个业务板块的针对性学习和全面了解，旨在提升员工的专业技能和市场洞察力。' },
                    { img: require('../../assets/images/news2402292.jpg'), text: '电梯事业部陈飞总  电梯业务知识培训' },
                    { img: require('../../assets/images/news2402293.jpg'), text: '飞鹿云张峰总  介绍飞鹿云各业务模块' },
                    { img: require('../../assets/images/news2402294.jpg'), text: '祖青总 企业文化团队素质培训' },
                    { img: require('../../assets/images/news2402295.jpg'), text: '汪总进行企业战略宣讲' },
                    { img: require('../../assets/images/news2402296.jpg'), text: '颁发“积极参与奖”' },
                    { img: require('../../assets/images/news2402297.jpg'), text: '在培训学习过程中，包括了丰富的互动环节，员工们积极参与和讨论，促进了跨部门之间的沟通和理解。活动在颁发“积极参与奖”的热烈气氛中落下帷幕，这次团建活动不仅成功地加强了员工对“全员营销”战略的理解，也有效提升了团队的士气和凝聚力。' },
                ],
                summarize: ''
            }
        },
        {
            addr: '/news/one',
            date: '2023.12.05',
            title: '祝贺富轹科技集团成功竞得霞浦经济技术开发区优质地块',
            img: '',
            author: 'frankmiao',
            content: {
                subheading: '12月5日，浙江富轹科技集团旗下关联公司成功竞得福建省霞浦县松港街道小沙村优质地块，成交价格：140610000（壹亿肆仟零陆拾壹万元）。',
                data: [
                    { img: require('../../assets/images/news231505.jpg'), text: '' },
                    { img: require('../../assets/images/items_xiapu01.jpg'), text: '浙江富轹科技集团将与霞浦地方政府联合打造集智能制造、冷链储存、海产品展示、电子商务为一体的高端海洋科技城。' },
                ],
                summarize: ''
            }
        },
        {
            addr: '/news/one',
            date: '2023.11.14',
            title: '温州团队开展“六顶思维帽”团建培训活动',
            img: '',
            author: 'frankmiao',
            content: {
                subheading: `11月11日，富轹科技集团组织温州常驻员工进行了一场以“6顶思维帽”游戏为形式，以“集团公司全员营销”为课题的团建培训活动。
                这次活动的目的在于“团队建立富有成效的企业文化和合作机制；个人培养多维、包容、高效的思维方式，并通过反复训练以巩固之。`,
                data: [
                    {
                        img: require('../../assets/images/news23111400.jpg'), text: `这种充分调动、寓教于乐，轻松而有效的团建方式是一次积极的尝试。后续我们将总结经验，结合工作实际，在集团内不断优化和推广。为提升员工综合素质、增强团队凝聚力，组织更多丰富而有效的团建活动，推进企业文化建设。
                    ` },
                    {
                        img: require('../../assets/images/news23111401.jpg'), text: `关于“六顶思维帽”:六顶思维帽是“创新思维之父”英国学者爱德华·德·波诺(Edward de Bono)博士开发的一种思维训练模式，是一个全面思考问题的模型。又称平行思考法，是一种同一时间只做一件事情的思考方法。
白色帽代表客观，红色帽代表情绪，黑色帽代表谨慎，黄色帽代表乐观，绿色帽代表创造，蓝色帽代表控制。` },
                    {
                        img: require('../../assets/images/news23111402.jpg'), text: `一个典型的六顶思维帽的应用步骤如下：
                    
1、陈述问题事实（白帽）
2、提出如何解决问题的建议（绿帽）
3、评估建议的优缺点：列举优点（黄帽）；列举缺点（黑帽）
4、对各项选择方案进行直觉判断（红帽）
5、归纳总结陈述，得出方案（蓝帽）` },
                    {
                        img: '', text: `为什么是帽子?

●不同颜色的帽子代表不同的思考规则。
●容易转换思考方式。
●帽子为组织思维提供了框架，使思维变得更加集中，更加有组织性，更有创造性。`
                    },
                    {
                        img: '', text: `6顶思维帽的价值

6顶思维帽提供了一种对问题和机会进行创造性思考或变通性思考的方法，使我们从更广的角度看问题，甚至从相反的角度来分析问题。它对人们思考以及讨论问题所起到的帮助在于:
1.角色扮演——思维的最大限制就是“自我防卫”，这些帽子使我们敢说，而不用担心伤害自我及他人。
2.引导注意力——6顶思维帽可以将我们的注意力引导到事情的6个层面。
3.方便——象征性的6顶思维帽提供了一种非常方便的方法，戴帽、摘帽、换帽，让你可以要求某人(包括你自己)变换思考的状态。
4.建立了游戏规则——6顶思维帽等于为思维游戏制定了一些规则，让人们在谈论和思维时有了共同的标准。
“思想的帽子”是一个很好的比喻，根据每顶帽子的喻义，我们可以辨析自己的态度和立场。开始时，这种方法好像很“做作"，但它提供了具有建设性和纲要性的思维框架，有助于整理问题，挖掘内在原因，换个角度看问题，能够将问分析得更透彻，更重要的是，能制定更好的决策与计划。`
                    },
                    {
                        img: '', text: `如何使用6顶思维帽

在大型或小型的会议讨论过程中，会议的组织和参与者可在会议进行的不同阶段，根据议题展开的情况，对6顶思维帽进行以下的些应用:
戴帽：
思维帽可以在思维的任何时候单一地使用，也可以将几顶帽子以一定的排列顺序合并起来使用，或者在思考前，按6顶思维帽的完整使用顺序建立一个思维的框架。
如果要对某事件或某问题进行充分讨论，那么，讨论小组中就要包括所有6种不同思维方式的人员。如果讨论小组的人员都是戴黑色帽子的，他们就不会支持革新或采用新的方法。
我们可以请同事或思考中的小组成员戴上特定颜色的帽子，或者我们请一个小组在几分钟之内使用某顶思维帽。例如:“我们停滞不前了。让我们戴上绿色的帽子，想想关于这个问题的新观点吧!"
值得一提的是，在整个思维过程中，蓝帽发挥着与众不同的作用。不管什么时候，不论我们建议下一步要用哪顶帽子时，我们实际上都是在使用蓝帽思维。
“请用蓝帽来决定我们想思考什么，我们要用哪顶思维帽。”
“我认为目前的讨论已偏离了我们的目标，我要戴上蓝帽，再陈述一下目标。”
“我想戴上我的蓝帽，问问我们能得出什么结论。”
蓝帽的使用不受其他帽子的限制，任何思维步骤都可以由它来提出。
摘帽：
我们可以请一个人或整个小组来摘掉某种特定颜色的帽子。我们推断正在进行的思维是某一类型，让人们从这种思维中脱离出来。例如:“好，这是很好的黑帽思维，让我们现在把黑帽收起来。”
换帽：
我们可以通过建议一位小组成员摘掉一个帽子，戴上另一个来完成思维的瞬间转换，同时我们也不会冒犯这个人，因为这不是攻击正在进行的思维而是要求改变。例如:“这是个不错的想法，现在请我们摘掉黄帽，戴上白帽，我们需要列出事实。”
传统性思维模式是把问题、事件、知识、研究所涉及的因素考虑得越复杂越是感觉万无一失。“6顶思维帽”突破了传统逻辑思维的单一模式，使我们将思考的不同方面分开进行，取代了一次解决所有问题的做法。集中分析信息(白帽)、利益(黄帽)、直觉(红帽)以及风险(黑帽)等，使人们可以依次对问题的不同侧面给予足够的重视和充分的考虑。它帮助人们做事更有效率，更专注，更加运用智慧的力量。`
                    },
                ],
                summarize: ''
            }
        },



        {
            addr: '/news/one',
            date: '2023.07.26',
            title: '富轹科技集团领导赴邯郸冀南新区考察',
            img: '',
            author: 'frankmiao',
            content: {
                subheading: '7月26日，浙江富轹科技集团有限公司副总裁汪振勇、麦弘毅、侯纪周一行到邯郸冀南新区考察，双方在管委会七楼东会议室召开座谈会议，对接交流相关事宜。邯郸冀南新区管委会主任杨万春，管委会副主任魏忠彦参加活动。',
                data: [
                    { img: require('../../assets/images/news072601.jpg'), text: '7月26日，浙江富轹科技集团有限公司副总裁汪振勇、麦弘毅、侯纪周一行到邯郸冀南新区考察，双方在管委会七楼东会议室召开座谈会议，对接交流相关事宜。邯郸冀南新区管委会主任杨万春，管委会副主任魏忠彦参加活动。' },
                    {
                        img: require('../../assets/images/news072602.jpg'), text: `杨万春说：
欢迎各位企业家，邯郸是一座等了你三千年的城。冀南新区是河北省设立的第三个战略发展新区，京津冀协同发展和中原经济区两大战略在这里交汇，区位优势明显、资源禀赋丰厚，是一块投资兴业的沃土。当前，新区正在实施创建国家高新技术产业开发区和建设滏阳新城两大主体战略，高新区创建与新城建设各项工作稳步推进，职业教育在这里蓬勃发展，产城教融合高质量发展势头强劲。近年来，新区立足区域产业定位，不断优化营商环境，创新开展“产业链+产业园”招商模式，持续招引高新企业、专精特新企业落户到新区，形成高端装备制造等产业聚集的良好局面。浙江富轹科技集团有限公司是一家从事技术服务、技术开发、技术咨询等业务的公司，在集成电路、自动化数字化、智能制造、产业园开发等领域基础雄厚、实践经验丰富。希望双方进一步加强对接沟通，寻找到契合点位，早日达成实质性合作，共同实现双赢目标。` },
                    {
                        img: require('../../assets/images/news072603.jpg'), text: `汪振勇说：
江富轹科技集团有限公司总部设于浙江省杭州市，是以服务国家战略性新兴产业的培育和发展为宗旨，依托创新的产业运营先进理念和商业模式，将政府、投资机构、优质的产业资源进行有效整合；专注于全国各类区域性产业园区项目规划及咨询服务，致力成为具有卓越实力及影响力的产业导入专家，构建以园区开发、产业投资、科技孵化为核心的产业资源聚合平台。邯郸冀南新区在特色产业集聚、生态治理、职业教育、城市建设等方面走在前列，独特的区位优势、深厚的资源禀赋和良好的营商环境成为投资兴业的理想之地。希望通过双方深入对接交流，尽快寻找到合作契合点，达成实质性合作。同时，我们将发挥集团优势，积极参与到新区建设中来，为新区发展贡献一份力量。` },
                    { img: require('../../assets/images/news072604.jpg'), text: '邯郸冀南新区管委会副主任魏忠彦主持座谈会，并介绍新区相关情况。' },
                    { img: require('../../assets/images/news072605.jpg'), text: '会后，考察团一行还到河北新富集团、河北运达风电设备有限公司、瑞宏新金属结构制造有限公司、中电光谷邯郸智造中心项目、邯郸·中关村信息谷创新中心、邯郸职教城、科创城、滏阳河生态修复工程（新区段）等地考察，实地了解产业生产、职业教育、城市建设、河流生态修复、项目建设等情况，并就相关问题进行深入交流。新区相关部门负责人参加活动。' },
                ],
                summarize: ''
            }
        },
        {
            addr: '/news/one',
            date: '2023.06.13',
            title: '机器人产业园企业参加温州技师学院校园招聘',
            img: '',
            author: 'frankmiao',
            content: {
                subheading: '6月13日，一场主题为“技能造富 职等你来”技能人才岗位进校园招聘会在温州技师学院如火如荼地展开。新沃电气、中环机械、斯普威尔电气、贝立电力、朗森等多家瓯江口机器人产业园企业参加了本次招聘会。',
                data: [
                    { img: require('../../assets/images/recruit01.jpg'), text: '招聘会上，园区企业共提供了数十个招聘岗位，包括钣金、焊接、机电、激光操作等多种技术岗位。相关招聘受到了学生的极大关注，现场气氛热烈。' },
                    { img: require('../../assets/images/recruit02.jpg'), text: '在招聘会现场，温州技师学院共700多名今年预就业学生分时段、分批次参加了本次招聘会，各家企业展示了公司的产品和服务，向学生介绍了公司的发展历程和未来规划。同时，各家企业还安排了专业的人力资源招聘团队，为应聘者提供详细的职位介绍和面试指导，并耐心解答了应聘者的各种问题。' },
                ],
                summarize: '参会企业表示，此次校园招聘会办得非常成功，不仅为公司引进了优秀的人才，也让更多的学生了解了企业的发展方向和未来愿景。此次校园招聘会的成功举办，标志着机器人产业园在校企合作方面又迈出了坚实的一步，今后机器人产业园将继续为园区企业提供优质的招聘、技改、咨询等平台服务，共同推动园区的发展进程。'
            }
        },
        {
            addr: '/news/one',
            date: '2023.05.25',
            img: '',
            title: '海经区管委会调研瓯江口机器人实训中心',
            author: 'frankmiao',
            content: {
                subheading: '5月25日，温州海经区管委会组宣部董建泽部长一行来校调研瓯江口机器人实训中心，副校长叶文、瓯江口机器人产业园负责人，组织宣传统战部、人力资源部、产教合作部、继续教育学院、智能控制学院相关人员陪同。',
                data: [
                    { img: require('../../assets/images/center01.jpg'), text: '董建泽部长一行对现场学生演示的机器人手动示教颇感兴趣。瓯江口机器人产业园负责人介绍，瓯江口机器人实训中心承办了2023CIMC（“西门子杯”中国智能制造挑战赛）“智能产线与协作机器人赛项”初赛培训，这也是“西门子杯”首次进入温州。调研当天，来自泉州信息工程学院的师生正在现场进行培训。“西门子杯”中国智能制造挑战赛是由教育部高等学校自动化类专业教学指导委员会、西门子（中国）有限公司和中国仿真学会联合主办的国家级A类赛事。自2006年发展至今，已经成功举办16届，为目前中国智能制造领域规模最大、规格最高的学生类竞赛。' },
                    { img: require('../../assets/images/center02.jpg'), text: '' },
                ],
                summarize: '培训围绕智能型协作机器人核心技术开展，为师生在智能制造的理论知识和操作能力方面奠定了良好的基础。学校厚植培育技能人才土壤、创新技能人才培养方式，持续推进技能人才精准培养输送，助力区域经济社会高质量发展。'
            }
        },
        {
            addr: '/news/one',
            date: '2023.05.16',
            img: '',
            title: '瓯江口机器人实训中心成功举办“西门子杯”中国智能制造挑战赛初赛培训',
            content: {
                subheading: '近日，由瓯江口机器人产业园、台湾达明机器人公司与温州技师学院三方，合作建立的瓯江口机器人实训中心承接举办的2023CIMC（“西门子杯”中国智能制造挑战赛）“智能产线与协作机器人赛项”初赛培训圆满完成。这也是“西门子杯”中国智能制造挑战赛首次进入温州。',
                data: [
                    { img: require('../../assets/images/train01.jpg'), text: '“西门子杯”中国智能制造挑战赛由教育部高等学校自动化类专业教学指导委员会、西门子（中国）有限公司和中国仿真学会联合主办的国家级A类赛事。自2006年发展至今，已经成功举办16届，为目前中国智能制造领域规模最大、规格最高的学生类竞赛。' },
                    { img: require('../../assets/images/train02.jpg'), text: '此次大赛赛程跨度达五个月，参赛选手需参加线下培训，线上考试，校内选拔，全国初赛直至八月份的全国总决赛。大赛主要面向全国控制科学与工程、电气工程、机械工程、仪表科学与工程、信息与通讯工程、计算机科技与技术等相关学科的研究生、本科生，和全国自动化类、机电设备类、机械设计制造类、电子信息类、计算机类及通信类等相关专业的高校学生，以企业真实的工程项目和科研项目作为竞赛赛题，以真实的工业设备和工业环境作为赛场，以工业企业的工程标准作为考核评分指标，全面锻炼学生解决复杂工程问题的综合能力、系统思维，开赛以来获得了参赛院校和师生的积极反响和称赞。' },
                ],
                summarize: ''
            }
        },
        {
            addr: '/news/one',
            date: '2023.05.09',
            img: '',
            title: '瓯江口机器人实训基地揭牌',
            content: {
                subheading: '5月25日，温州海经区管委会组宣部董建泽部长一行来校调研瓯江口机器人实训中心，副校长叶文、瓯江口机器人产业园负责人，组织宣传统战部、人力资源部、产教合作部、继续教育学院、智能控制学院相关人员陪同。',
                data: [
                    { img: require('../../assets/images/opening01.jpg'), text: '温州市人民政府副市长章月影，温州海洋经济发展示范区党工委书记、管委会主任黄阳栩，市经济与信息化局党委委员、副局长黄寿君，市人力资源和社会保障局党组成员、副局长庄加灵，中共温州市委社会建设委员会专职副主任许绍策，及温州技师学院相关领导参加了仪式。' },
                    { img: require('../../assets/images/opening02.jpg'), text: '海经区管委会主任黄阳栩，温州技师学院校长刘杰，瓯江口机器人产业园及台湾达明机器人公司的代表，共同为实训基地揭牌' },
                    { img: require('../../assets/images/opening03.jpg'), text: '瓯江口机器人实训基地位于温州技师学院内，面积约600平米，是目前温州规模最大的一个功能齐全、设备先进的现代化机器人实训及研发基地。实训基地内配置了最新型的视觉融合协作机器人、机器人仿真软件、机器人编程软件、机器人自动化生产线等各种机器人研发和制造所需的设备和工具，并配备了专业的培训教师及技术人员，可以为学生和企业提供全方位的机器人及自动化实践培训服务，并为企业提供各类机器人与自动化的定制研发服务。' },
                    { img: require('../../assets/images/opening04.jpg'), text: '实训基地的建成，将吸引更多的优秀人才加入机器人产业，培养更多的机器人专业人才。同时，实训基地也可以为学生提供实践机会，让他们更好地了解和掌握机器人研发和制造的技术，提高他们的就业竞争力，有力促进温州机器人与自动化技术的不断进步和创新，为产业升级做出更大的贡献。' },
                ],
                summarize: ''
            }
        }
    ],
    items_datas: [
        {
            addr: '/multiple/one',
            date: '',
            title_cn: '大信智谷-瓯江口机器人产业园',
            img: require('../../assets/images/items_ojk01.jpg'),
            title_en: 'Daxin i valley- Oujiangkou Robot Industrial Park',
            content: {
                subheading: '瓯江口机器人产业园是由富轹科技导入中信华晟基金与阿里云在温州瓯江口共同打造的高端工业园区，于 2019 年 10 月 21 日在互联网大会签约，2020 年 3 月初正式开工建设，为浙江省重点项目，“152”工程。',
                subheading_en: 'Oujiangkou Robot Industrial Park is a high-end industrial park jointly built by Citic Huasheng Fund and Alibaba Cloud in Oujiangkou, Wenzhou, introduced by Fuke Technology. It was signed at the Internet Conference on October 21, 2019, and officially started construction in early March 2020. It is a key project in Zhejiang Province, the “152” project.',
                data: [
                    { img: require('../../assets/images/items_ojk01.jpg'), text: '园区总用地面积约355 亩， 总建筑面积约 60 万平方米， 总投资约 25亿元。聚集多家领先机器人制造商、智能科技企业。', en: 'The total land area of the park is approximately 355 acres, with a total construction area of approximately 600000 square meters and a total investment of approximately 2.5 billion yuan. Gathering multiple leading robot manufacturers and intelligent technology enterprises.' },
                    { img: require('../../assets/images/items_ojk02.jpg'), text: '园区重点建设机器人研发和生产基地、机器人应用示范区、机器人博物馆及配套工程。将打造一个以机器人制造、系统集成、零部件生产、智能产业示范等生态链组成的产业集群。力争成为浙江乃至全国的智能制造示范基地，并重塑城市工业新格局、点亮温州智造新未来。', en: 'The park focuses on building robot research and production bases, robot application demonstration areas, robot museums, and supporting projects. We will create an industrial cluster composed of ecological chains such as robot manufacturing, system integration, component production, and intelligent industry demonstration. Strive to become an intelligent manufacturing demonstration base in Zhejiang and even the whole country, and reshape the new pattern of urban industry, lighting up a new future of intelligent manufacturing in Wenzhou.' },
                ],
                summarize: ''
            }
        },
        {
            addr: '/multiple/one',
            date: '',
            title_cn: '大信智谷-霞浦海洋数字经济产业园',
            img: '',
            title_en: 'DAXIN iVALLEY · XIAPU Marine Digital Economy Industrial Park',
            content: {
                subheading: '霞浦海洋数字经济产业园是大信智谷依托国家、区域发展战略和政策环境优势，深入推进一二三产业融合发展，在福建霞浦打造的集“生产+加工+科技研发+品牌+电商”全产业链的现代海洋数字经济产业园。',
                subheading_en: 'Xiapu Marine Digital Economy Industrial Park is a modern marine digital economy industrial park built by DAXIN iVALLEY in Xiapu, Fujian Province, relying on national and regional development strategies and policy environment advantages to deeply promote the integration of primary, secondary, and tertiary industries. It integrates the entire industrial chain of “production+processing+technology research and development+brand+e-commerce”.',
                data: [
                    { img: require('../../assets/images/items_xiapu01.jpg'), text: '产业园由富轹科技集团联合知名电商平台阿里巴巴、搭建第三方平台子平台运作模式，助力“霞浦海洋经济商务产业集聚区”打造，带领园区品牌开拓国内外市场。项目建设内容包括：海产品加工智能车间、海产品精加工车间、海产品智慧冷链物流基地、产品展销中心、综合配套大楼（含直播电商基地、总部办公基地、水产育苗科研中心、智能化设备定制中心、员工配套服务）等。', en: 'The industrial park is jointly operated by Fully Technology Group and well-known e-commerce platform Alibaba to establish a third-party platform operation model, helping to build the “Xiapu Marine Economic and Commercial Industry Cluster Zone” and leading the park brand to explore domestic and foreign markets. The construction content of the project includes: intelligent workshop for seafood processing, precision processing workshop for seafood, intelligent cold chain logistics base for seafood, product exhibition and sales center, comprehensive supporting building (including live streaming e-commerce base, headquarters office base, aquatic breeding research center, intelligent equipment customization center, and employee supporting services), etc.' },
                    { img: require('../../assets/images/items_xiapu02.jpg'), text: '霞浦海洋数字经济产业园打造配套齐全、运营模式创新、多功能型产业园区“实体产业+线上产业”的示范基地；打造海产品智能化精加工车间，力争成为省级海洋产业数字化、自动化转型示范基地；打造海产品加工”智能工厂”的示范基地，致力于成为推动霞浦海洋经济转型更新的创新智能引擎。', en: 'The Xiapu Marine Digital Economy Industrial Park aims to create a demonstration base for the “physical industry+online industry” of a fully equipped, innovative operation model, and multifunctional industrial park; Build an intelligent precision processing workshop for seafood, striving to become a provincial-level demonstration base for digital and automated transformation of the marine industry; Building a demonstration base for “intelligent factories” in seafood processing, committed to becoming an innovative intelligent engine for promoting the transformation and updating of Xiapu’s marine economy.' },
                ],
                summarize: ''
            }
        },
        {
            addr: '/multiple/one',
            date: '',
            title_cn: '上海大信无人机通航港',
            img: '',
            title_en: 'SHANGHAI DAXIN UAV NAVIGATION PORT',
            content: {
                subheading: '项目由大信科融联合中信海直发展有限公司联合投资建设，项目占地200亩，总投资15亿元，汇聚了旋翼机制造企业、无人机整机制造企业，无人机配套设备制造企业 ，无人机配套软件开发企业，无人机驾驶员培训企业，并建立大信5G 泛低空无人机大数据中心、智能无人机华东制造中心基地。',
                subheading_en: 'The project is jointly invested and constructed by Daxin Kerong and CITIC Offshore Helicopter, the project covers an area of 200 mu, with a totalinvestment of 1.5 billion yuan. It brings together rotorplane manufacturer, drone complete machine manufacturing enterprises, drone corollary equipment manufacturers, drone bundled software developer, UAV drone operator training institutes, building Daxin 5G extensive low altitude drone big data center and East China drone Intelligent manufacturing base.',
                data: [
                    { img: require('../../assets/images/items_shw01.jpg'), text: '', en: '' },
                    { img: require('../../assets/images/items_shw02.jpg'), text: '', en: '' },
                ],
                summarize: ''
            }
        }
    ],
    about_datas: {
        title: '品牌简介',
        title_en: 'BRAND INTRODUCTION',
        img: require('../../assets/images/dxzg_about.jpg'),
        foot_cn: '大信智谷，智慧引领未来工业地产。',
        foot_en: 'DAXIN i VALLEY, wisdom leads the future of industrial real estate.',
        data: [
            {
                cn: '大信智谷是由富轹科技集团与大信科融、中信、中国电子及阿里云等大型集团的旗下机构联合打造，提供产业导入及投资、园区开发及运营等全链条服务的、具有优秀资源整合能力的工业地产综合平台。',
                en: 'DAXIN i VALLEY is a comprehensive industrial real estate platform with excellent resource integration capability, which is jointly built by Fully Technology Group, Daxin Kerong, CITIC, China Electronics, Alibaba Cloud and other large groups. It provides full chain services such as industrial introduction and investment, park development and operation.'
            },
            {
                cn: '秉承“实业与金融相互依存，传统与新兴彼此促进”的中信理念，大信智谷致力于持续推动中国传统工业地产向智慧地产升级，逐步形成和造就中国产业导入和园区规划的领导品牌。',
                en: 'Adhering to the CITIC philosophy of “interdependence between industry and finance, and mutual promotion between tradition and emerging”, DAXIN i VALLEY is committed to continuously promoting the upgrading of traditional industrial real estate in China to smart real estate, gradually forming and cultivating a leading brand for China’s industrial introduction and park planning.'
            },
            {
                cn: '大信智谷不仅为地方政府提供含高端制造园区开发、产业聚集、企业甄选等区域型经济发展能效升级的整体解决方案，同时也为企业提供产业用地适配、智能制造升级、知识产权、品牌策划、政策咨询等个性化解决方案。此外，大信智谷还为合作伙伴提供基于工业地产的资产优化配置、项目全生命周期管理、软资源要素的优化整合。无论是地方政府、企业还是合作伙伴，大信智谷都能够提供全方位、个性化的服务，为客户的发展提供有力支持。',
                en: 'DAXIN i VALLEY not only provides local governments with comprehensive solutions for regional economic development and energy efficiency upgrading, including high-end manufacturing park development, industrial clustering, and enterprise selection, but also personalized solutions for enterprises such as industrial land adaptation, intelligent manufacturing upgrading, intellectual property, brand planning, and policy consulting. In addition, DAXIN i VALLEY also provides partners with optimized asset allocation based on industrial real estate, project lifecycle management, and optimization and integration of soft resource elements. Whether it is local governments, enterprises, or partners, DAXIN i VALLEY can provide comprehensive and personalized services, providing strong support for the development of customers.'
            },
        ],
    },
    service_datas: [
        {
            addr: '/multiple/one',
            title: '科技孵化',
            title_cn: '作为大信智谷的控股母公司，富轹科技集团与中国电子、阿里巴巴、哈工大、熊猫电子等产业龙头深度合作，打造各高新技术领域产业运营和企业孵化的平台',
            img: require('../../assets/images/services01.png'),
            title_en: '',
            content: [
                { title: '◎ 机器人产业', discript: '富轹集团与中电熊猫、哈工大机器人集团、格润智控、台湾达明等国内外知名的工业机器人企业开展了深度合作，在电子、制鞋、汽摩配、装备制造等领域的自动化设备改造方面投入了具体的研发费用，并形成了多行业、自动化设备+数字化平台的整体解决方案。' },
                { title: '◎ IC集成电路', discript: '富轹科技集团与中国电子旗下的中电港合资成立了中电港科技，专注于集成电路应用的开发，与国内外多家IC原厂合作，形成从IC应用 、模块开发、IC贸易、金融服务为一体的集成电路应用服务。' },
                { title: '◎ 高新电子', discript: '富轹科技集团以控股的雷亚电子为基础，专注于电源、物联网设备领域的开发，形成了设备制造、物联网解决方案、国际贸易、电商服务为一体的产业平台。' },
            ]
        },
        {
            addr: '/multiple/one',
            title: '产业导入',
            title_cn: '富轹科技集团以“科技+投资”为核心模式，聚焦区域产业链，赋能产业升级，融合数字经济，助力地方政府高质量发展制造业的目标。富轹产业基金支持运作，致力于政府产业规划、企业发展需求、金融产业资本的对接、整合和转化。',
            img: require('../../assets/images/services02.jpg'),
            title_en: '通过强强联合，全面发展金融资本、互联网、智能制造等领域的战略合作。以中信旗下华晟基金为代表的金融战略合作伙伴，通过专业化投资运营和资源整合，提供全链条产业金融综合服务；以阿里云为代表的互联网战略合作伙伴，共同建设地方工业互联网中心；以哈工严格集团、中电熊猫装备、中信重工为代表的智能制造战略合作伙伴，共同推进传统企业智能化改造。',
            content: []
        },
        {
            addr: '/multiple/one',
            title: '园区开发',
            title_cn: '服务地方政府， 配合区域发展规划进行高端主题产业园的策划立项 、招商引资 、开发建设及运营管理。已成功立项并完成建设开发如：瓯江口机器人产业园、中信海直上海无人机通航港、中国电子温州信息港等一批优质的产业园项目。',
            img: require('../../assets/images/services03.jpg'),
            title_en: 'Provide partners with optimized asset allocation based on industrial real estate, project lifecycle management, and optimized integration of soft resource elements.',
            content: []
        },
        {
            addr: '/multiple/one',
            title: '数智运营',
            title_cn: '以可视化、物联网技术为基础，运用AI人工智能、大数据、云计算、智慧消防、机器人、融合通信等先进技术，实现车辆识别、人脸识别、火灾预警、智能运维、智能分析、消防数据上报、机器人巡检，保证了音视频、数据的高速传输，从而完成了数据大融合和海量数据云存储，实现整个园区的综合管控和调度指挥，且大屏大数据魔墙可使生产数据、业务数据图表化实时上墙，为安全生产、安全监管提供有力保障。',
            img: require('../../assets/images/services02.png'),
            title_en: 'Provide partners with optimized asset allocation based on industrial real estate, project lifecycle management, and optimized integration of soft resource elements.',
            content: []
        }
    ],
    business_datas: [
        {
            addr: '',
            title: '',
            title_cn: '大信智谷不仅为地方政府提供含高端制造园区开发、产业聚集、企业甄选等区域型经济发展能效升级的整体解决方案；同时也为企业提供产业用地适配、智能制造升级、知识产权、品牌策划、政策咨询等个性化解决方案。此外，大信智谷还为合作伙伴提供基于工业地产的资产优化配置、项目全生命周期管理、软资源要素的优化整合。无论是地方政府、企业还是合作伙伴，大信智谷都能够提供全方位、个性化的服务，为客户的发展提供有力支持。',
            img: require('../../assets/images/business01.png'),
            title_en: '',
            content: [
                { title: '◎ 孵化导入', discript: '发挥平台优势，对接智能制造、互联网技术的领军品牌，聚焦区域产业链，赋能产业升级，融合数字经济，服务地方工业高质量发展。' },
                { title: '◎ 园区开发', discript: '服务地方政府，配合区域发展规划进行高端主题产业园区的策划立项、招商引资、开发建设及运营管理。' },
                { title: '◎ 产业投资', discript: '高效整合金融资源，依托品牌基金，以产业投资为核心，服务国家战略，支持项目建设。' },
            ]
        }
    ],
    other_datas: {
        title: '战略规划',
        title_en: 'STRATEGIC PLANNING',
        img: require('../../assets/images/dxzg_about.jpg'),
        foot_cn: '大信理念',
        foot_en: 'DAXIN i VALLEY, wisdom leads the future of industrial real estate.',
        data: [
            {
                cn: '未来1~3年：深入部署品牌战略；持续推进优质产业项目，建设运营8-10个大信智谷高端园区，运营面积力争达到200万平方米。',
            },
            {
                cn: '未来3~5年：提升公司品牌知名度、广泛度、美誉度，导入建设5个大型单体项目，建设运营20个大信智谷园区，运营面积力争达到500万平方米。',
            },
            {
                cn: '未来5~10年：深耕长三角及珠三角，开并拓二三线城市及城镇化新兴产业市场，实现工业地产智慧化运营的战略构想，力争运营面积达到1500万平方米。',
            },
        ],
        right: {
            title: '大信理念',
            title_en: 'PHILOSOPHY',
            img: '',
            content: ['—— 实业与金融相互依存，传统与新兴彼此促进。', '—— 秉持共赢发展，聚合优质资源，服务铸就品牌。', '—— 打造最具影响力的全链条产业服务平台。']
        },
        bottom: {
            title: '诚邀合作',
            title_en: 'SINCERELY INVITE CONPERATION',
            img: '',
            content: ['大信智谷立足于雄厚的产业基础和实践经验，以产业园咨询为起点，在科技赋能、企业孵化、资本导入、产业投资、园区开发、数智运营等领域，与众多央企国企、各领域领先品牌、以及地方政府形成了长期战略合作。我们面向各级地方政府，各类企业及项目，提供全链条的、专业的智能化、数字化产业投资运营咨询服务。大信智谷产业智库，热忱欢迎您的垂询与合作！']
        }
    },
    info_datas: [
        {
            addr: '',
            data: '国家高新技术产业开发区可享受国家哪些优惠政策？',
            title: '高新技术企业按15%的税率征收所得税，其中出口产品产值达到当年总产值70%以上的，按10%税率征收所得税',
            img: require('../../assets/images/information01.jpg'),
            author: 'frankmiao',
            content: {}
        },
        {
            addr: '',
            data: '',
            img: '',
            title: '新办的高新技术企业，从投产年度起，两年内免征所得税，新办的中外合资经营的高新技术企业，合营期在十年以上的，可从获利年度起，头两年免征所得税',
            author: 'frankmiao',
            content: {}
        },
        {
            addr: '',
            data: '',
            img: '',
            title: '内资办的高新技术企业新建技术开发和生产经营用房，免征建设税（或投资方向调节税）',
            content: {}
        },
        {
            addr: '',
            data: '',
            img: '',
            title: '高新技术企业用于高新技术开发和高新技术产品生产的仪器、设备，可实行快速折旧',
            content: {}
        },
        {
            addr: '',
            data: '',
            img: '',
            title: '经海关批准，高新技术企业可在高新区内设保税仓库、保税工厂',
            content: {}
        },
        {
            addr: '',
            data: '',
            img: '',
            title: '高新技术企业生产的出口产品，除国家限制出口或者有规定的产品以外，免征出口关税',
            content: {}
        },
        {
            addr: '',
            data: '',
            img: '',
            title: '高新技术产业开发区内的基础设施建设和生产、经营性基本建设项目，国家每年安排一定数额的基本建设贷款和投资规模，并可发行一定额度的长期债券，向社会筹集资金',
            content: {}
        },
        {
            addr: '',
            data: '',
            img: '',
            title: '国家规定的其他优惠政策',
            content: {}
        }
    ],

};

export default datas;