import { useNavigate } from 'react-router-dom';
import descriptions from '../../components/descriptions';
import React from 'react';
import { Carousel, Layout, Image, Row, Col } from 'antd';
import './index.scss';
const { Header, Content, Footer } = Layout;
class Profile extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      title: '品牌简介',
    }
  }

  componentDidMount() {
  }

  componentWillReceiveProps(nextProps) {
  }
  render() {
    const { other_datas } = descriptions;
    return (
      <Layout style={{ backgroundColor: '#FFFFFF' }}>
          <div className="page-content">
            <Row justify='center' >
            <Col xs={{ span: 18 }} sm={{ span: 18 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }} className="a-left-other">
              <p className="cn">{other_datas.title}</p>
              <p className="en">{other_datas.title_en}</p>
              <div className="msg">
                {
                  other_datas.data.map((item,indexs)=>{
                    return <p key={indexs}><p>{item.cn}</p></p>
                  })
                }
              </div>
            </Col>
            <Col xs={{ span: 18 }} sm={{ span: 18 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }} className="a-right-other">
            <p className="cn">{other_datas.right.title} </p>
            <p className="en">{other_datas.right.title_en}</p>
              <div className="msg">
                {
                  other_datas.right.content.map((item,indexs)=>{
                    return <p key={indexs}><p>{item}</p></p>
                  })
                }
              </div>
             
            </Col>
            </Row>
            <Row justify='center' className='a-bottom-other'>
              <Col span={18}>
              <p className='cn'>{other_datas.bottom.title}</p>
              <p className="en">{other_datas.bottom.title_en}</p>
              <div className="msg">
                {
                  other_datas.bottom.content.map((item,indexs)=>{
                    return <p key={indexs}><p>{item}</p></p>
                  })
                }
              </div>
            </Col>
            </Row>
            <Row justify='center'>
              <Col span={18}>
              <Image src={other_datas.img.default} preview={false}/>
            </Col>
            </Row>
          </div>
      </Layout>
    )
  }
}


export default Profile;
