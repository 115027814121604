import { useNavigate } from 'react-router-dom';
import React from 'react';
import { Carousel, Layout, Image, Row, Col } from 'antd';
import Headertop from '../../components/Headertop';
import Linenav from '../../components/Linenav';
import Foot from '../../components/Foot';
import LinkWithAnimation from '../../components/Linkanimation';
import descriptions from '../../components/descriptions';
import bener from '../../assets/images/dx_071101.jpg';
import home01 from '../../assets/images/home_brand.jpg';
import home04 from '../../assets/images/home_industarial.jpg';
import home02 from '../../assets/images/home_project.jpg';
import home03 from '../../assets/images/home_news.jpg';
import './index.scss';
export const withNavigation = (Component) => {
  return (props) => <Component {...props} navigate={useNavigate()} />;
};


class Home extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      title: '首页',
      code: ['首页', '关于我们', '新闻中心', '业务介绍', '投资关系', '联系我们'],
      company: '大信智谷',
      companyInfo: '大信智谷是由富轹科技集团与大信科融、中信、中国电子及阿里云等大型集团的旗下机构联合打造，提供产业导入及投资、园区开发及运营等全链条服务的、具有优秀资源整合能力的工业地产综合平台',
      isOpen: false
    }
  }

  componentDidMount() {
    document.body.classList.add('sidebar-closed');
  }
  componentDidUpdate() {
    const { isOpen } = this.state;
    const bodyClass = document.body.classList;
    if (isOpen) {
      bodyClass.remove('sidebar-closed');
      bodyClass.add('sidebar-open');
    } else {
      bodyClass.remove('sidebar-open');
      bodyClass.add('sidebar-closed');
    }
  }
  componentWillReceiveProps(nextProps) {

  }
  getRouteitems = (e) => {
    this.setState({
      routeitems: e
    })
  }
  getKeyrout = (e) => {
    this.setState({
      keyRout: e
    })
  }
  leaveOut = () => {
    this.props.navigate('/about/profile')
  }
  getOpen = (e) => {
    this.setState({
      isOpen: e
    })
  }
  getOpens = (e) => {
    const bodyClass = document.body.classList;
    if (e) {
      bodyClass.remove('sidebar-open');
      bodyClass.add('sidebar-closed');
    } else {
      bodyClass.remove('sidebar-closed');
      bodyClass.add('sidebar-open');
    }
  }
  goAddr = (e) => {
    console.log(e, 'eee');
    this.props.navigate(e)
  }
  render() {
    const { isOpen, new_datas, items_datas } = descriptions;
    return (
      <Layout className='main-container'>
        <div className="sidebar">
          <Linenav getOpen={this.getOpens} />
        </div>
        <div className="page-content">
          <Headertop getRouteitems={this.getRouteitems} getKeyrout={this.getKeyrout} getOpen={this.getOpen} />
          <Image className='nav-image' src={bener} preview={false} />
          <div className='section1'>
            <Row justify="center" className='section1-header' gutter={{ xs: 8, sm: 16, md: 24, lg: 32, xl: 40 }} >
              <Col xs={{ span: 0 }} sm={{ span: 0, offset: 0 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }} onClick={this.leaveOut}>
                <div className='stokt-box '>
                  <h3 className='stoke-name'>{this.state.company}</h3>
                  <div className='news-clamp'>{this.state.companyInfo}</div>
                </div>
              </Col>
              {/* <div className='vertical-line'></div> */}
              <Col className='fisrtNews-box' xs={{ span: 20, offset: 0 }} sm={{ span: 20, offset: 0 }} md={{ span: 6, offset: 0 }} lg={{ span: 6, offset: 0 }} xl={{ span: 6, offset: 0 }}>
                <a href="#" onClick={()=>this.goAddr('/news/newsone')}>
                  <h3>{new_datas[0].title}</h3>
                  <p>{new_datas[0].date}</p>
                  <span>
                    {new_datas[0].content.subheading}
                  </span>
                </a>
              </Col>
              {/* <div className='vertical-line'></div> */}
              <Col className="newsList-box" xs={{ span: 0 }} sm={{ span: 0, offset: 0 }} md={{ span: 6, offset: 0 }} lg={{ span: 6, offset: 0 }} xl={{ span: 6, offset: 0 }}>
                <div>
                  <a href="#" onClick={this.goAddr('/news/newsone')}>
                    <span className="news-header">新闻中心</span>
                  </a>
                  <div className="show-more">
                    <a href="#" onClick={this.goAddr('/multiple/services')}>
                      <span>查看更多</span>
                    </a>
                  </div>
                </div>
                <ul>

                  {
                    new_datas.filter((item, indexs) => indexs < 5).map((item, indexs) => {
                      return <li key={indexs}>
                        <a href='#' onClick={() => this.goAddr(`${item.addr}?${indexs}`)}>
                          <span className="news-time">{item.date} </span><span className="news-title">{item.title}</span>
                        </a>
                      </li>
                    })
                  }
                </ul>
              </Col>
            </Row>
          </div>

          <div className='section2'>
            <Row justify="center">
              <Col xs={{ span: 0, offset: 0 }} sm={{ span: 0 }} md={{ span: 7 }} lg={{ span: 7 }} xl={{ span: 7 }}>
                <a href='#' onClick={this.goAddr('/multiple/logistics')}>
                  <div className="colony-content">
                    <h4>项目展示</h4>
                    <div className='linkcentent'>
                      <LinkWithAnimation to="/multiple/one?0" text={items_datas[0].title_cn} />
                      <LinkWithAnimation to="/multiple/one?1" text={items_datas[1].title_cn} />
                      <LinkWithAnimation to="/multiple/one?2" text={items_datas[2].title_cn} />
                    </div>
                  </div>
                </a>
              </Col>
              <Col xs={{ span: 20 }} sm={{ span: 20 }} md={{ span: 11 }} lg={{ span: 11 }} xl={{ span: 11 }} className="mix-duoyuan-box">
                <Row justify='center' style={{ backgroundColor: '#fff' }} gutter={[8, 8]}>
                  <Col span={12} className="mix-duoyuan-item  leftFloat">
                    <a href='#' className="item it1" onClick={() => this.goAddr('/about/profile')}>
                    {/* <div className="icon"></div>
                      <div className="cn">品牌简介</div>
                      <div className="en">BRAND INTRODUCTION</div> */}
                    <Image src={home01} preview={false} />
                    <div className="shadow"><i></i></div>
                    <i className="enter"></i>
                    </a>
                  </Col>
                  <Col span={12} className="mix-duoyuan-item  leftFloat">
                    <a href='#' className="item it2" onClick={() => this.goAddr('/multiple/logistics')}>
                      {/* <div className="icon"></div>
                      <div className="cn">项目展示</div>
                      <div className="en">PROJECT PRESENTATION</div> */}
                      <Image src={home02} preview={false} />
                      <div className="shadow"><i></i></div>
                      <i className="enter"></i>
                    </a>
                  </Col>
                  <Col span={12} className="mix-duoyuan-item  leftFloat ">
                    <a href='#' className="item it3" onClick={() => this.goAddr('/news/newsone')}>
                      {/* <div className="icon"></div>
                      <div className="cn">新闻中心</div>
                      <div className="en">NEWS CENTER</div> */}
                      <Image src={home03} preview={false} />
                      <div className="shadow"><i></i></div>
                      <i className="enter"></i>

                    </a>
                  </Col>
                  <Col span={12} className="mix-duoyuan-item  leftFloat">
                    <a href='#' className="item it4" onClick={() => this.goAddr('/multiple/services')}>
                      {/* <div className="icon"></div>
                      <div className="cn">产业服务</div>
                      <div className="en">INDUSTRIAL SERVICES</div> */}
                      <Image src={home04} preview={false} />
                      <div className="shadow"><i></i></div>
                      <i className="enter"></i>
                    </a>
                  </Col>
                </Row>
                <div>

                </div>
              </Col>
            </Row>
          </div>
          <Foot />
        </div>
      </Layout>
    )
  }
}


export default withNavigation(Home);
