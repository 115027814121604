import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Row, Col, Layout, Image } from 'antd';
import './0613.scss';
import descriptions from '../../components/descriptions';
//ERAYAK
const { Header, Content, Footer } = Layout;


const TargetComponent = () => {
    const { new_datas } = descriptions
    const location = useLocation();
    const indexs = Number(location.search[1]);

    useEffect(() => {
        console.log(indexs); // 打印 indexs 的值
    }, [indexs]);
    const datas = new_datas[indexs]
    const { date } = datas
    const lastIndex = date.lastIndexOf('.');
    const beforeDot = date.slice(0, lastIndex);
    const afterDot = date.slice(lastIndex + 1);
    return (
        <Layout style={{ margin: '0 auto' }}>
            <Row justify='center'>
            <Col className='module-content' xs={{ span: 0 }} sm={{ span: 0 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                    <div className="fadeUp">
                        <div className="title-en">COMPANY NEWS</div>
                        <div className="title-cn">公司要闻</div>
                    </div>
                </Col>
                <Col className='news-detail' xs={{ span: 18 }} sm={{ span: 18 }} md={{ span: 13 }} lg={{ span: 13 }} xl={{ span: 13 }}>
                <Row justify='start' >
                        <Col span={24} style={{ minHeight: '500px' }} >
                            <div style={{ position: 'relative', marginBottom: '20px' }}>
                                <div className="time-content">
                                    <div className="time">
                                        <p>{afterDot}</p>
                                        <span>{beforeDot}</span>
                                    </div>
                                </div>
                                <div className="news-content-title">
                                    <span className="vertical-center">{datas.title}</span>
                                </div>
                            </div>
                            <Row gutter={[16, 16]} style={{ marginTop: '24px' }}>
                                <Col xs={24} sm={24} md={24}>
                                    <p style={{lineHeight: '26px', fontSize: '16px', color:'#444444'}}>{datas.content.subheading}</p>
                                </Col>
                            </Row>

                            <Row gutter={[16, 16]} style={{ marginTop: '24px'}}>
                                {datas.content.data.map((item, index) => (
                                    <Col xs={24} sm={12} md={12} key={index}>
                                        <div  style={{ textAlign: 'center' }}>
                                            <Image src={item.img.default} preview={false} style={{ display: 'inline-block' }} />
                                            <pre style={{ lineHeight: '26px', textAlign: 'left', fontSize: '16px', color: '#444444', whiteSpace: 'pre-wrap' }}>{item.text}</pre>
                                        </div>
                                    </Col>
                                ))}
                            </Row>
                            
                            <Row gutter={[16, 16]} style={{ marginTop: '24px', lineHeight: '26px', fontSize: '16px' }}>
                                <Col xs={24}>
                                    <p style={{lineHeight: '26px', fontSize: '16px', color:'#444444'}}>{datas.content.summarize}</p>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Layout>
    )
};




export default TargetComponent;
