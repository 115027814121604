import { useNavigate } from 'react-router-dom';

import React from 'react';
import './index.scss';
import { ClockCircleOutlined } from '@ant-design/icons';
import { Timeline, Layout, Row, Col } from 'antd';
const { Header, Content, Footer } = Layout;

class History extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      title: '发展历程',
      companyInfo:
        [
          {
            year: 2009,
            info: ['2009年9月,公司创立。', '聚焦工业制造业和贸易业务'],
            img: '',
            color: '',
            dot: true
          },
          {
            year: 2009,
            info: ['与阿里云战略合作，推进工业信息化产业'],
            img: '',
            color: '',
            dot: true
          },
          {
            year: 2009,
            info: ['投资雷亚电子,开发电源物联网设备产业。'],
            img: '',
            color: '',
            dot: true
          },
          {
            year: 2009,
            info: ['2009年9月,公司创立。', '聚焦工业制造业和贸易业务'],
            img: '',
            color: '',
            dot: true
          },
          {
            year: 2009,
            info: ['与阿里云战略合作，推进工业信息化产业'],
            img: '',
            color: '',
            dot: true
          }


        ]
    }
  }

  componentDidMount() {
  }

  componentWillReceiveProps(nextProps) {
  }
  render() {
    const { code } = this.state;

    return (
      <Layout>
        
          <Row justify='center' className="page-content" style={{ backgroundColor: '#FFFFFF' }}>
            <Col span={18} className="center-content">
              <p className="en">FIRM MILESTONES</p>
              <p className="cn">2009年成立至今</p>
              {/* <img src="https://www.xnskg.cn/wp-content/themes/xnskg/static/images/aboutus_clock_icon.png"> */}
            </Col>
            <Col span={18}>
            <Timeline
              mode="alternate"
              // pending={true}
              reverse={true}
              items={[

                {
                  children: (<div ><span>2009</span><div><p>2009年9月,公司创立。</p><p>聚焦工业制造业和贸易业务</p></div></div>),
                },
                {
                  children: (<div ><span>2013</span><div><p>与阿里云战略合作，推进工业信息化产业</p></div></div>),
                  color: 'green',
                },
                {
                  dot: (
                    <ClockCircleOutlined
                      style={{
                        fontSize: '16px',
                      }}
                    />
                  ),
                  children: (<div ><span>2014</span><div><p>投资雷亚电子,开发电源物联网设备产业。</p></div></div>),
                },
                {
                  color: 'red',
                  children: (<div ><span>2015</span><div><p>
                    与中国电子科技集团有限公司合作，
                    推动产业园区板块业务。</p></div></div>),
                },
                {
                  children: (<div ><span>2016</span><div><p>投资雷亚电子,开发电源物联网设备产业。</p></div></div>),
                },
                {
                  dot: (
                    <ClockCircleOutlined
                      style={{
                        fontSize: '16px',
                      }}
                    />
                  ),
                  children: (<div ><span>2018</span><div>
                    <p>与中国电子旗下中电港合资成立中电港科技,推进IC集成电路开发。</p>
                  </div></div>),
                },
                {
                  children: (<div ><span>2019</span><div>
                    <p>6月,与熊猫电子装备合作,开发鞋业自动化产业。8月,中农发(香港)公司正式控股,。</p>
                    <p>8月,中农发(香港)公司正式控股,全面推进工业互联网及智能制造的发展。</p>
                    <p>10月,出席云栖大会,第六届世界互联网大会,与瓯江口产业集聚区签订合作协议。</p>
                    <p>11月,与阿里云正式合作,设立飞鹿云计算(温州)有限公司,成为阿里云在温州的工业互联网区域平台。</p>
                    <p>12月,与中信华晟基金合作,设立温州瓯晟科技发展有限公司,进行瓯江口机器人产业园建设。</p>
                  </div></div>),

                },
                {
                  children: (<div ><span>2020</span><div><p>与深圳文德智控、格润智能合资合作。
                  </p></div></div>),
                },
                {
                  children: (<div ><span>2021</span><div><p>与哈工大机器人集团达成战略合作
                    。</p></div></div>),
                },
                {
                  children: (<div ><span>2022</span><div><p>12月14日,旗下雷业电子 正式于美国纳斯达克上市交易
                    。</p></div></div>),
                }
              ]}
            />
            </Col>
          </Row>
        <Footer style={{ backgroundColor: '#FFFFFF', padding: '24px 0' }}>
        </Footer>

      </Layout>
    )
  }
}


export default History;
