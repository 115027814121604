import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import { ZoomInOutlined } from '@ant-design/icons';
import './index.scss';

const Linkanimation = ({ to, text }) => {
  return (
    <Link to={to} className="link-with-animation">
      {/* <Button type="primary" shape="round" icon={<ZoomInOutlined />} size="large"> */}
        {text}
      {/* </Button> */}
    </Link>
  );
};

export default Linkanimation;