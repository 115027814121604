
import React from 'react';
import Index from '../pages/Index/index.js';
import Home from '../pages/Home';
import New from '../pages/New';
import About from '../pages/About';
import Newsone from '../pages/Newsone';
import Connects from '../pages/Join';
import Multiple from '../pages/Multiple';
import Investor from '../pages/Investor';
import Profile from '../pages/Profile';
import History from '../pages/History';
import Culture from '../pages/Culture';
import Team from '../pages/Team';
import Qualification from '../pages/Qualification';
import Responsibility from '../pages/Responsibility';
import Connect from '../pages/Connect';
import Logistics from '../pages/Logistics';
import Services from '../pages/Services';
import Comprehensive from '../pages/Comprehensive';
import Other from '../pages/Other';
import Information from '../pages/Information';
import Announcements from '../pages/Announcements';
import Governance from '../pages/Governance';
import Stock from '../pages/Stock';
import Kjj from '../components/news/0613';
import Kji from '../components/items/index';
import Exchange from '../pages/Exchange';
import Email from '../pages/Email';
import People from '../pages/People';
import Recruitment from '../pages/Recruitment';
let routes = [
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "about",
    element: <About />,
    children: [
      {
        path: "",
        element: <Profile />,
      },
      {
        path: "profile",
        element: <Profile />,
      },
      {
        path: "history",
        element: <History />,
      },
      {
        path: "business",
        element: <Culture />,
      },
      {
        path: "team",
        element: <Team />,
      },
      {
        path: "qualification",
        element: <Qualification />,
      },
      {
        path: "responsibility",
        element: <Responsibility />,
      },
      // {
      //   path: "connect",
      //   element: <Connect />,
      // }
    ]
  },
  {
    path: "news",
    element: <New />,
    children: [
      {
        path: "",
        element: <Newsone />,
      },
      {
        path: "newsone",
        element: <Newsone />,
      },
      {
        path: "information",
        element: <Index />,
      },
      // {
      //   path: "governance",
      //   element: <Governance />,
      // },
      {
        path: "stock",
        element: <Stock />,
      },
      {
        path: "one",
        element: <Kjj />,
      },

    ]
  },
  {
    path: "multiple",
    element: <Multiple />,
    children: [
      {
        path: "",
        element: <Logistics />,
      },
      {
        path: "logistics",
        element: <Logistics />,
      },
      {
        path: "services",
        element: <Services />,
      },
      // {
      //   path: "omprehensive",
      //   element: <Comprehensive />,
      // },
      {
        path: "other",
        element: <Other />,
      },
      {
        path: "one",
        element: <Kji />,
      },
      

    ]
  },
  {
    path: "investor",
    element: <Investor />,
    children: [
      {
        path: "",
        element: <Information />,
      },
      {
        path: "information",
        element: <Information />,
      },
      {
        path: "announcements",
        element: <Announcements />,
      },
      // {
      //   path: "governance",
      //   element: <Governance />,
      // },
      // {
      //   path: "stock",
      //   element: <Stock />,
      // },
      {
        path: "exchange",
        element: <Exchange />,
      },
      {
        path: "email",
        element: <Email />
      }

    ]
  },
  {
    path: "connect",
    element: <Connects />,
    children: [
      {
        path: "",
        element: <Connect />,
      },
      {
        path: "people",
        element: <People />,
      },
      {
        path: "recruitment",
        element: <Recruitment />,
      },
      {
        path: "connect",
        element: <Connect />,
      }
    ]
  },
];
 
export default routes;