import './index.scss';
import { useNavigate } from 'react-router-dom';
import React from 'react';
import { Tabs, Layout, Image, Row, Col } from 'antd';
import routes from '../../routes/index';
import wechatImg from '../../assets/images/dxzg_vx.jpg';
import retract from '../../assets/images/home_retract_icon.png';
import dropdown from '../../assets/images/home_dropdown_icon.png';
import { HomeOutlined, UserOutlined } from '@ant-design/icons';
const { Footer } = Layout;


export const withNavigation = (Component) => {
    return (props) => <Component {...props} navigate={useNavigate()} />;
};


class Foot extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
      displayWechat: 'none',
      displayOne: 'none',
      displayTwo: 'none',
      retractOne: retract,
      retractTwo: retract,
        }

    }

    componentWillReceiveProps = (nextProps) => {
    }
    
  changeWechat = () => {
    this.setState({
      displayWechat: 'block'
    })
  }
  onMouseOut = () => {
    this.setState({
      displayWechat: 'none',
      displayTwo: 'none',
      displayOne: 'none',
      retractTwo: retract,
      retractOne: retract,
    })
  }
  itemOne = () => {
    this.setState({
      displayOne: 'block',
      retractOne: dropdown
    })
  }
  itemTwo = () => {
    this.setState({
      displayTwo: 'block',
      retractTwo: dropdown
    })
  }
    render() {
        return (
            <Footer style={{ backgroundColor: '#FFFFFF', padding: '24px 0' }}>
          <div className="footer-link">
            <Row justify='center' className="clearfix" style={{ height: '59px' }}>
              <Col xs={{span: 18}} sm={{span: 18}} md={{span: 11}} lg={{span: 11}} xl={{span: 11}} className="foot-wx">
                <span style={{fontSize:'16px'}}>关注大信智谷</span>
                <div className="foot-wx-icon">
                    <div className="wx-icon" onMouseOver={this.changeWechat} onMouseOut={this.onMouseOut} ></div>
                    <Image src={wechatImg} style={{
                      width: '70px',
                      display: this.state.displayWechat,
                      height: 'auto',
                    }}
                    />
                  </div>
              </Col>
              <Col xs={{span: 0}} sm={{span: 0}} md={{span: 7}} lg={{span: 7}} xl={{span: 7}} onMouseOver={this.itemOne} onMouseOut={this.onMouseOut} className="footerFloat item1">
                <div className="link">
                  <span>相关网站</span>
                  <Image src={this.state.retractOne} preview={false} />
                </div>
                <ul className="ul1" style={{ bottom: '21px', textAlign: 'left', display: this.state.displayOne }}>
                  <li><a href="http://47.99.123.93/" target="_blank">富轹科技集团</a></li>
                </ul>
              </Col>
            </Row>
          </div>

          <Row justify='center' className="footer-law">
            <Col xs={{span: 18}} sm={{span: 18}} md={{span: 18}} lg={{span: 18}} xl={{span: 18}}>
            <p>大信智谷（浙江）科技发展有限公司</p>
            <p><a href="https://beian.miit.gov.cn/" target="_blank">浙ICP备2023009564号-2</a></p>
            <p>版权所有·法律声明·隐私政策·技术支持</p>
            <p>地址：浙江省杭州市环站东路768号富亿花园中心T3楼</p>
            </Col>
          </Row>
        </Footer>
        )
    }
}


export default withNavigation(Foot);
