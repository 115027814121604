import React, { Suspense } from 'react';
import './App.css';
import { Route, Routes, Navigate } from 'react-router-dom';
import routes from './routes';
import Home from './pages/Home';
function App() {

  const routerRender = (routes) => {
    return routes.map((item, index) => {
      return (
        <Route
          key={index}
          path={item.path}
          element={<item.element />}
        >
          {item.children ? routerRender(item.children) : null}
        </Route>
      )
    });
  };

  return (
    <div className="App">
      {/* <Header /> */}
      <Suspense fallback={<div></div>}>
        <Routes>

          <Route path={"*"} element={<Navigate to="/" />} />
          {/* <Route path={"/news/newsone"} element={Newsone} /> */}
          {routes.map((item, index) => {
            return item.children ? (
              <Route
                key={index}
                path={item.path}
                element={item.element}
              // exact 
              >
                {/* <Route exact index element={<Home/>} /> */}
                {item.children.map((items, val) => {
                  return (<Route
                    key={val}
                    path={items.path}
                    element={items.element}
                    exact
                  />)
                })}
              </Route>

            ) :
              <Route
                key={index}
                path={item.path}
                element={item.element}
                exact
              />;
          })}


        </Routes>

      </Suspense>
    </div>
  );
}

export default App;