import { useNavigate } from 'react-router-dom';

import React from 'react';
import { Carousel, Layout, Image, theme } from 'antd';
const { Header, Content, Footer } = Layout;
class Profile extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      title:'',
    }
  }

  componentDidMount() {
  }

  componentWillReceiveProps(nextProps) {
  }
  render() {
    const { code } = this.state;

    return (
      <Layout>
        
        <Content className="site-layout" style={{}}>
        Profile
        </Content>
        <Footer style={{ backgroundColor: '#FFFFFF', padding: '24px 0' }}>
        </Footer>
      </Layout>
    )
  }
}


export default Profile;
