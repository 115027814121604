import { useNavigate } from 'react-router-dom';

import React from 'react';
import { Row, Col, Layout, Image } from 'antd';
import {
  NotificationOutlined
} from '@ant-design/icons';
import './index.scss'
import descriptions from '../../components/descriptions';
const { Header, Content, Footer } = Layout;

export const withNavigation = (Component) => {
  return (props) => <Component {...props} navigate={useNavigate()} />;
};

class Business extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      title: '业务说明',
    }
  }
  componentDidMount() {
  }
  componentWillReceiveProps(nextProps) {
  }
  render() {
    const { business_datas } = descriptions;
    return (
      <Layout style={{ margin: '0 auto' }}>
        <Row justify='center'>
          <Col className='module-content' xs={{ span: 0 }} sm={{ span: 0 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
            <div className="fadeUp">
              <div className="title-en">BUSINESS DESCRIPTION</div>
              <div className="title-cn">业务说明</div>
            </div>
          </Col>
          <Col className='news-detail' xs={{ span: 18 }} sm={{ span: 18 }} md={{ span: 13 }} lg={{ span: 13 }} xl={{ span: 13 }}>
            <Row justify='start'>
              <Col span={24} style={{ minHeight: '500px' }} >
                <ul className='ul-left'>
                  {business_datas.map((item, indexs) => {
                    return <li key={indexs}>
                      <div>
                        <p className="services-title" style={{ padding: '0' }}>
                          {item.title}
                        </p>
                        <p className="services-titlecn" style={{ padding: '0' }}>
                          {item.title_cn}
                        </p>
                        <Image style={{ marginTop: '2vh' }} src={item.img.default} preview={false} />
                        <div>
                          {item.content != '' ?
                            item.content.map((e, val) => {
                              return (<div key={val}><p className="contnent-title" style={{ padding: '0' }}>
                                {e.title}
                              </p>
                                <p className='news-content'>{e.discript}</p>
                              </div>
                              )
                            })
                            :
                            null
                          }
                        </div>
                      </div>
                    </li>
                  })}
                </ul>
              </Col>
            </Row>
          </Col>
        </Row>
      </Layout>
    )
  }
}


export default withNavigation(Business);
