import './index.scss';
import { useNavigate } from 'react-router-dom';
import { NavLink as Link } from 'react-router-dom';
import React from 'react';
import {
  CloseOutlined,
  MenuOutlined
} from '@ant-design/icons';
import { Layout, Menu, Image, Row, Col } from 'antd';
import routes from '../../routes/index';
import logo from '../../assets/images/dxzg_logo.png'
const { Header } = Layout;


export const withNavigation = (Component) => {
  return (props) => <Component {...props} navigate={useNavigate()} />;
};


class Headertop extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      collapsed: true,
      isOpen: false,
      mode: window.innerWidth < 768 ? 'vertical' : 'horizontal',
      code: [
        { title: '首页', key: '/home' },
        { title: '关于我们', key: '/about' },
        { title: '新闻中心', key: '/news' },
        { title: '业务介绍', key: '/multiple' },
        { title: '投资者关系', key: '/investor' },
        { title: '联系我们', key: '/connect' },
      ],
      selectId: '2',
      routeItems: [],
      items: [{
        key: "/",
        label: <Link to="/home">首页</Link>
      }, {
        key: "/about",
        label: <Link to="/about">关于我们</Link>,
        children: [{
          key: "/about/profile",
          label: <Link to="/about/profile">品牌简介</Link>
        }, {
          key: "/about/history",
          label: <Link to="/about/history">发展历程</Link>
        },
          {
            key: "/about/business",
            label: <Link to="/about/business">业务说明</Link>
          }, 
          // {
          //   key: "/about/team",
          //   label: <Link to="/about/team">管理团队</Link>
          // }, {
          //   key: "/about/qualification",
          //   label: <Link to="/about/qualification">资质荣誉</Link>
          // }, {
          //   key: "/about/responsibility",
          //   label: <Link to="/about/responsibility">社会责任</Link>
          // }, 
          // {
          //   key: "/about/connect",
          //   label: <Link to="/about/connect">联系我们</Link>
          // }
        ]
      }, {
        key: "/news",
        label: <Link to="/news">新闻中心</Link>,
        children: [{
          key: "/news/newsone",
          label: <Link to="/news/newsone">公司要闻</Link>
        }, {
          key: "/news/information",
          label: <Link to="/news/information">产业资讯</Link>
        },
          //  { key: "/news/governance",
          //   label: <Link to="/news/governance">科技动态</Link>
          // }, 
          // {
          //   key: "/news/stock",
          //   label: <Link to="/news/stock">公告信息</Link>
          // }
        ]
      }, {
        key: "/multiple",
        label: <Link to="/multiple">业务介绍</Link>,
        children: [{
          key: "/multiple/logistics",
          label: <Link to="/multiple/logistics">项目展示</Link>
        }, {
          key: "/multiple/services",
          label: <Link to="/multiple/services">产业服务</Link>
        },
        // {
        //   key: "/multiple/omprehensive",
        //   label: <Link to="/multiple/omprehensive">科技孵化</Link>
        // },
        {
          key: "/multiple/other",
          label: <Link to="/multiple/other">战略规划</Link>
        }]
      },
      // {
      //   key: "/investor",
      //   label: <Link to="/investor">投资者关系</Link>,
      //   children: [{
      //     key: "/investor/information",
      //     label: <Link to="/investor/information">公司资料</Link>
      //   }, {
      //     key: "/investor/announcements",
      //     label: <Link to="/investor/announcements">公司公告</Link>
      //   }, {
      //     key: "/investor/exchange",
      //     label: <Link to="/investor/exchange">投资者互动</Link>
      //   }, {
      //     key: "/investor/email",
      //     label: <Link to="/investor/email">投资者信箱</Link>
      //   }]
      // }, 
      {
        key: "/connect",
        label: <Link to="/connect">联系我们</Link>,
        children: [
          //   {
          //   key: "/connect/people",
          //   label: <Link to="/connect/people">人才发展</Link>
          // },
          {
            key: "/connect/connect",
            label: <Link to="/connect/connect">联系我们</Link>
          }]
      }
      ]
    }
  }
  handleResize = () => {
    this.setState({
      mode: window.innerWidth < 768 ? 'vertical' : 'horizontal',
    });
  };
  componentDidMount = () => {
    const { items } = this.state
    items.forEach(item => {
      if (item.label.props.children == this.props.name) {
        this.props.getKeyrout(item.children[0].key)
        this.props.getRouteitems(item)
      }
    })
    window.addEventListener('resize', this.handleResize);

  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }
  componentWillReceiveProps(nextProps) {

  }
  changeRoute = (e) => {
    const routItem = e.keyPath[e.keyPath.length - 1];
    let routeItems = [];
    const { items } = this.state
    items.forEach(item => {
      if (item.key == routItem) {
        routeItems.push(item)
      }
    })
    this.props.getKeyrout(e.key)
    this.props.getRouteitems(routeItems[0])
    this.setState({
      selectId: e.key,
      routeItems
    })
  }
  toggleCollapsed = () => {
    this.setState((prevState) => ({
      collapsed: !prevState.collapsed,
    }));
  };
  closeNav = () => {
    this.props.getOpen(false)
    this.setState({
      isOpen: false
    })
  };
  openNav = () => {
    this.props.getOpen(true)
    this.setState({
      isOpen: true
    })
  };
  goHome = () => {
    this.props.navigate('/')
  };
  render() {
    const { isOpen, items, collapsed } = this.state;
    return (
      <div className='header' style={{ top: 0, zIndex: 1, width: '100%' }}>
        <div className='nav-switch'>
          <CloseOutlined style={{ display: isOpen == true ? 'block' : 'none' }} className='nav-close' onClick={this.closeNav} />
          <MenuOutlined style={{ display: isOpen == false ? 'block' : 'none' }} className='nav-open' onClick={this.openNav} />
        </div>
        <Row justify="center" className='headermid'>

          <Col xs={{ span: 7 }} sm={{ span: 7 }} md={{ span: 7 }} lg={{ span: 7 }} xl={{ span: 7 }}>
            <Image className='logo' src={logo} style={{ width: 140, height: 'auto' }} preview={false} onClick={this.goHome} />
          </Col>
          <Col className='upmenu' xs={{ span: 0 }} sm={{ span: 11 }} md={{ span: 11 }} lg={{ span: 11 }} xl={{ span: 11 }}>
            <Menu
              className='menutitle nav'
              theme="light"
              mode={this.state.mode}
              // selectedKeys={['/about']}
              onSelect={this.changeRoute}
              items={items}
            >
            </Menu>
          </Col>
        </Row>
      </div>
    )
  }
}


export default withNavigation(Headertop);
