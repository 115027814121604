import { useNavigate } from 'react-router-dom';
import descriptions from '../../components/descriptions';
import React from 'react';
import { Carousel, Layout, Image, Row, Col } from 'antd';
import './index.scss';
const { Header, Content, Footer } = Layout;
class Profile extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      title: '品牌简介',
    }
  }

  componentDidMount() {
  }

  componentWillReceiveProps(nextProps) {
  }
  render() {
    const { about_datas } = descriptions;
    return (
      <Layout style={{ backgroundColor: '#FFFFFF' }}>
          <div className="page-content">
            <Row justify='center' >
            <Col xs={{ span: 18 }} sm={{ span: 18 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }} className="a-left-content">
              <p className="date">SINCE2009</p>
              <p className="cn">{about_datas.title}</p>
              <p className="en">{about_datas.title_en}</p>
              <div className="msg">
                {
                  about_datas.data.map((item,indexs)=>{
                    return <p key={indexs}><p>{item.cn}</p><p>{item.en}</p></p>
                  })
                }
                <p>

                </p>
              </div>
            </Col>
            <Col xs={{ span: 18 }} sm={{ span: 18 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }} className="a-right-content">
              <div className="msg">
                <p className="cn">{about_datas.foot_cn} </p>
                <p className="en">{about_datas.foot_en}</p>
              </div>
            </Col>
            </Row>
            <Row justify='center'>
              <Col span={18}>
              <Image src={about_datas.img.default} preview={false}/>
            </Col>
            </Row>
          </div>
      </Layout>
    )
  }
}


export default Profile;
