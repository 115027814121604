import React from 'react';
import { AppstoreOutlined, MailOutlined, SettingOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import { NavLink as Link } from 'react-router-dom';
function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}
const items = [{
  key: "/",
  label: <Link to="/home">首页</Link>
}, {
  key: "/about",
  label: <Link to="/about">关于我们</Link>,
  children: [{
    key: "/about/profile",
    label: <Link to="/about/profile">品牌简介</Link>
  }, {
    key: "/about/history",
    label: <Link to="/about/history">发展历程</Link>
  },
  {
    key: "/about/business",
    label: <Link to="/about/business">业务说明</Link>
  },
    // {
    //   key: "/about/team",
    //   label: <Link to="/about/team">管理团队</Link>
    // }, {
    //   key: "/about/qualification",
    //   label: <Link to="/about/qualification">资质荣誉</Link>
    // }, {
    //   key: "/about/responsibility",
    //   label: <Link to="/about/responsibility">社会责任</Link>
    // }, 
    // {
    //   key: "/about/connect",
    //   label: <Link to="/about/connect">联系我们</Link>
    // }
  ]
}, {
  key: "/news",
  label: <Link to="/news">新闻中心</Link>,
  children: [{
    key: "/news/newsone",
    label: <Link to="/news/newsone">公司要闻</Link>
  }, {
    key: "/news/information",
    label: <Link to="/news/information">产业资讯</Link>
  },
    //  { key: "/news/governance",
    //   label: <Link to="/news/governance">科技动态</Link>
    // }, 
    // {
    //   key: "/news/stock",
    //   label: <Link to="/news/stock">公告信息</Link>
    // }
  ]
}, {
  key: "/multiple",
  label: <Link to="/multiple">业务介绍</Link>,
  children: [{
    key: "/multiple/logistics",
    label: <Link to="/multiple/logistics">项目展示</Link>
  }, {
    key: "/multiple/services",
    label: <Link to="/multiple/services">产业服务</Link>
  },
  // {
  //   key: "/multiple/omprehensive",
  //   label: <Link to="/multiple/omprehensive">科技孵化</Link>
  // },
  {
    key: "/multiple/other",
    label: <Link to="/multiple/other">战略规划</Link>
  }]
},
// {
//   key: "/investor",
//   label: <Link to="/investor">投资者关系</Link>,
//   children: [{
//     key: "/investor/information",
//     label: <Link to="/investor/information">公司资料</Link>
//   }, {
//     key: "/investor/announcements",
//     label: <Link to="/investor/announcements">公司公告</Link>
//   }, {
//     key: "/investor/exchange",
//     label: <Link to="/investor/exchange">投资者互动</Link>
//   }, {
//     key: "/investor/email",
//     label: <Link to="/investor/email">投资者信箱</Link>
//   }]
// }, 
{
  key: "/connect",
  label: <Link to="/connect">联系我们</Link>,
  children: [
    //   {
    //   key: "/connect/people",
    //   label: <Link to="/connect/people">人才发展</Link>
    // },
    {
      key: "/connect/connect",
      label: <Link to="/connect/connect" >联系我们</Link>
    }]
}
];

class Linenav extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  onClick = () => {
    this.props.getOpen(true)
  };
  render() {
    return (
      <Menu
        onClick={this.onClick}
        style={{
          width: '100%',
          fontSize: '15px',
          backgroundColor: '#F9F7F3'
        }}
        defaultSelectedKeys={['1']}
        defaultOpenKeys={['sub1']}
        mode="inline"
        items={items}
      />
    )
  }
}
export default Linenav;