import { useNavigate } from 'react-router-dom';

import React from 'react';
import { Row, Col, Layout, Image } from 'antd';
import {
  PauseOutlined
} from '@ant-design/icons';
import './index.scss'
import descriptions from '../../components/descriptions';
const { Header, Content, Footer } = Layout;

export const withNavigation = (Component) => {
  return (props) => <Component {...props} navigate={useNavigate()} />;
};

class Logistics extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      title:'项目展示',
    }
  }
  componentDidMount() {
  }
  leaveOut=(item,indexs)=>{
    this.props.navigate(`${item[indexs].addr}?${indexs}`)
  }
  componentWillReceiveProps(nextProps) {
  }
  render() {
    const  {items_datas}  = descriptions;
    return (
      <Layout style={{ margin: '0 auto' }}>
        <Row justify='center'>
        <Col className='module-content' xs={{ span: 0 }} sm={{ span: 0 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
            <div className="fadeUp">
              <div className="title-en">PROJECT PRESENTATION</div>
              <div className="title-cn">项目展示</div>
            </div>
          </Col>
          <Col className='news-detail' xs={{ span: 18 }} sm={{ span: 18 }} md={{ span: 13 }} lg={{ span: 13 }} xl={{ span: 13 }}>
            <Row justify='start' >
              <Col span={24} style={{ minHeight: '500px'}} >
              <ul className='ul-left'>
                  {items_datas.map((item,indexs)=>{
                    return <li key={indexs}>
                    <div onClick={() => this.leaveOut(items_datas, indexs)}>
                      <p className="news-date">{item.date}</p>
                      <div>
                        <p className="news-title" style={{padding:'0'}}>
                          {item.title_cn} 
                          <PauseOutlined style={{color:'#ddc8ab', fontSize:'18px'}}/>
                         <span style={{color:'#666666'}}> {item.title_en}</span>
                          </p>
                        <Image style={{marginTop:'2vh'}} src={item.img.default} preview={false}/>
                        <p className='news-content'>{item.content.subheading}</p>
                      </div>
                    </div>
                  </li>
                  })}
                </ul>
              </Col>
            </Row>
          </Col>
        </Row>
      </Layout>
    )
  }
}


export default withNavigation(Logistics);
