import './index.scss';
import { useNavigate } from 'react-router-dom';
import React from 'react';
import { Tabs, Layout, Breadcrumb, Row, Col } from 'antd';
import routes from '../../routes/index';
import { HomeOutlined, UserOutlined } from '@ant-design/icons';
const { Header } = Layout;


export const withNavigation = (Component) => {
    return (props) => <Component {...props} navigate={useNavigate()} />;
};


class Nextroute extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            routeitems: [],
            routeTabs: [],
            keyRout: '',
            childrenRout: ''
        }

    }
    componentDidMount = () => {
        this.props.navigate('/news/newsone')
    }
    componentWillReceiveProps = (nextProps) => {
        console.log(nextProps, 'next');
        let routeTabs = [];
        let childrenRouts = '';
        let { keyRout, childrenRout } = this.state
        if (nextProps) {
            if (nextProps.routeitems != '') {
                nextProps.routeitems.children.forEach(items => {
                    routeTabs.push({
                        label: items.label.props.children,
                        key: items.key
                    })
                    if (nextProps.keyRout == items.label.props.to && childrenRout == '') {
                        childrenRouts = items.label.props.children
                        this.setState({
                            childrenRout: childrenRouts
                        })
                    }
                })
            }
            this.setState({
                routeTabs,
                routeitems: nextProps.routeitems
            })

            if (keyRout == '') {
                this.setState({
                    keyRout: nextProps.keyRout
                })
            }

        }
    }
    changeTabs = (e) => {
        this.props.navigate(e)

    }
    onTabClick = (e) => {
        let { routeTabs } = this.state
        routeTabs.forEach((item) => {
            if (e == item.key) {
                this.setState({
                    childrenRout: item.label,
                    keyRout: e
                })


            }
        })

    }
    render() {
        const { routeitems, routeTabs, keyRout, childrenRout } = this.state;
        let crumbtitle = '';
        let crumbhref = '';
        if (routeitems != '') {
            crumbtitle = routeitems.label.props.children;
            crumbhref = routeitems.label.props.to
        }
        return (
            // <div className='headers' style={{ position: 'sticky', top: 0, zIndex: 1, width: '100%' }}>
            <Row justify='center'>
                <Col xs={{ span: 0 }} sm={{ span: 0 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                    <Breadcrumb
                        separator=">"
                        style={{ fontSize: '15px', lineHeight: '70px' }}
                        items={[
                            {
                                title: (
                                    <>
                                        <HomeOutlined />
                                        <span>{crumbtitle}</span>
                                    </>
                                ),
                                href: crumbhref
                            },
                            {
                                title: childrenRout,
                            }
                        ]}
                    />
                </Col>
                <Col xs={{ span: 18 }} sm={{ span: 18 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                    <Tabs
                        size='large'
                        // tabPosition='left'
                        activeKey={keyRout}
                        defaultActiveKey='3'
                        onChange={this.changeTabs}
                        items={routeTabs}
                        onTabClick={this.onTabClick}
                        className='tabs'
                    />
                </Col>
            </Row>
            // </div>
        )
    }
}


export default withNavigation(Nextroute);
