import { Outlet } from 'react-router-dom';
import { Map, Marker,InfoWindow } from 'react-amap';
import React from 'react';
import Foot from '../../components/Foot';
import { Carousel, Layout, Image, Col, Row } from 'antd';
import content_02 from "../../assets/images/content_02.jpg";
import dxzg_vx from "../../assets/images/dxzg_vx.jpg";
import { AMAP_API_KEY } from '../../assets/links/config';
import Nextroute from '../../components/Nextroute';
// import { HomeOutlined, UserOutlined } from '@ant-design/icons';
import './index.scss';
const x = 120.2;
const y = 30.3;
const { Header, Content, Footer } = Layout;
class Connect extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      title: '联系我们',
      center: {
        longitude: 120.219284,
        latitude: 30.297215
      },
      size: {
        width: 250,
        height: 200
      },
      offset: [-5, -5]
    }
  }
  componentDidMount() {
  }

  componentWillReceiveProps(nextProps) {
  }
  render() {
    const { code } = this.state;

    return (
      <Layout>
        
        {/* <Connect className="site-layout" style={{ background: '#FFFFFF' }}> */}
          <Row justify='center'>
            <Col xs={{ span: 0 }} sm={{ span: 0 }} md={{ span: 10 }} lg={{ span: 10 }} xl={{ span: 10 }} className="cu-l">
              <div style={{textAlign:'left'}}>
                  <p className="cu-title">contact us</p>
                  <Image style={{width:'60%',height: 'auto'}} src={content_02} preview={false} />
              </div>
            </Col>
            <Col xs={{ span: 18 }} sm={{ span: 18 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }} className="cu-r">
              <p>大信智谷（浙江）科技发展有限公司</p>
              <p style={{marginBottom:'4vh',color:'#0063fb'}}>Daxin Zhigu (Zhejiang) Technology Development Co., Ltd</p>
              <p>中国 浙江 杭州市 环站东路768号富亿花园中心T3楼</p>
              {/* <p>电话：</p> */}
              <p>公众号： <Image style={{width:'50px',height: 'auto'}} src={dxzg_vx}/></p>
              <i className='arrow'></i>
            </Col>
          </Row>
          {/* <div id="calltrackMap" /> */}
          <Row justify='center' style={{ marginTop: '5vh', height: '50vh' }} >
            <Col span={24}>
            <Map
              amapkey={AMAP_API_KEY}
              viewMode='2D'
              zoom={17}
              plugins={["ToolBar"]}
              center={this.state.center}
            // style={{ width: '200px', height: '200px' }}
            >
              <Marker
                position={this.state.center}
                clickable
              // content={'dd'}
              />
              <InfoWindow
            position={this.state.positionD}
            visible={4}
            content={'html4'}
            isCustom={false}
          />
            </Map>
            </Col>
          </Row>
        {/* </Connect> */}
      </Layout>
    )
  }
}


export default Connect;
