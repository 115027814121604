import { useNavigate, Outlet } from 'react-router-dom';

import React from 'react';
import { Carousel, Layout, Image, Row, Col } from 'antd';
import Headertop from '../../components/Headertop';
import Nextroute from '../../components/Nextroute';
import Linenav from '../../components/Linenav';
import Foot from '../../components/Foot/index';
import Aboutimg from '../../assets/images/about01.png';
import './index.scss';

const { Header, Content, Footer } = Layout;

export const withNavigation = (Component) => {
  return (props) => <Component {...props} navigate={useNavigate()} />;
};

class connects extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      title: '联系我们',
      routeitems: [],
      keyRout: '',
      isOpen: false
    }
  }

  componentDidMount() {
    document.body.classList.add('sidebar-closed');
  }
  componentDidUpdate() {
    const { isOpen } = this.state;
    const bodyClass = document.body.classList;
    if (isOpen) {
      bodyClass.remove('sidebar-closed');
      bodyClass.add('sidebar-open');
    } else {
      bodyClass.remove('sidebar-open');
      bodyClass.add('sidebar-closed');
    }
  }
  componentWillReceiveProps(nextProps) {
  }
  getRouteitems = (e) => {
    this.setState({
      routeitems: e
    })
  }
  getKeyrout = (e) => {
    this.setState({
      keyRout: e
    })
  }
  getOpen = (e) => {
    this.setState({
      isOpen: e
    })
  }
  getOpens = (e) => {
    const bodyClass = document.body.classList;
    if (e) {
      bodyClass.remove('sidebar-open');
      bodyClass.add('sidebar-closed');
    } else {
      bodyClass.remove('sidebar-closed');
      bodyClass.add('sidebar-open');
    }
  }
  render() {
    return (
      <Layout className='main-container'>
        <div className="sidebar">
          <Linenav getOpen={this.getOpens} />
        </div>
        <div className="page-content">
          <Headertop name={this.state.title} getRouteitems={this.getRouteitems} getKeyrout={this.getKeyrout} getOpen={this.getOpen} />
          <Image className='nav-image' src={Aboutimg} preview={false} />
          <Nextroute routeitems={this.state.routeitems} keyRout={this.state.keyRout} />

          <Outlet />
          <Foot />
        </div>
      </Layout>
    )
  }
}


export default withNavigation(connects);
